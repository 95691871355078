<template lang="pug">
  div
    v-dialog(
      v-model="openDialog"
      width="1080"
      transition="dialog-top-transition"
      eager
    )
      v-card
        v-row(no-gutters)
          v-col(cols="4")
            v-toolbar(
              dense
              color="purple"
              dark
              flat
            )
              span Transaction Form
            create-voucher-transaction(
              :voucher-id="voucherId"
              :edit.sync="voucherChequeEdit"
            )
          v-col(cols="8")
            v-toolbar(
              dense
              color="primary"
              dark
              flat
            )
              span Transaction List
              v-spacer
              v-btn(
                color="white"
                text
                small
                dense
                @click="$refs.tableChequeNumber.voucherTransactionGetByVoucher()"
              )
                v-icon mdi-recycle
            table-voucher-transactions(
              ref="tableChequeNumber"
              :voucher-id="voucherId"
              v-model="voucherChequeEdit"
              :voucher-amount="voucherAmount"
            )
</template>
<style lang="sass" scoped>
  .rotate-icon
    animation: rotate-continuously 1s infinite alternate

  @keyframes rotate-continuously
    0%
      transform: rotate(0deg) scale(1)
    100%
      transform: rotate(360deg) scale(1.5)
</style>
<script>
export default {
  name: 'VoucherTransaction',
  props: ['voucher', 'value'],
  components: {
    createVoucherTransaction: () => import('./Create'),
    tableVoucherTransactions: () => import('./Table'),
  },
  data () {
    return {
      voucherChequeEdit: {},
    }
  },
  computed: {
    voucherAmount () {
      if (!this.voucher || this.$objectEmpty(this.voucher)) return 0
      return this.voucher.details.reduce((t, item) => t + item.amount, 0)
    },
    voucherId () {
      if (!this.voucher) return null
      return this.voucher.id
    },
    openDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>